import { version } from '../version';

export const environment = {
  theme: 'silicon',
  apiBaseUrl: 'https://dev-api.cgportal.co.uk',
  mockApiUrl: '',
  useMockAPI: false,
  name: 'testing',
  production: false,
  version,
  region: 'eu-west-2',
  user_pool_id: 'eu-west-2_VUCCMV33o',
  client_id: 'tq7b5nfu6re5hm1408tb77vpj',
  media_url: 'https://dev-media.cgportal.co.uk/',
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic2lsaWNvbi1jZ3ciLCJhIjoiY2tybmh2OXhtMXc5bDJ3cHZrOGFkNmt4bCJ9.O2cEBjFWzLZmB4G0bo0V4A',
  },
  cookie_domain: 'dev.cgportal.co.uk',
  gtm_id: 'GTM-TX9LCS3',
  terms_version: 2, // keep this as an integer, don't do "1.1"
  open_api_key: 'JYmHrqQdjHHZnI8rwT7c7QIxQQEJ0k09ffGhrchf',
  postman_api_key: '',
};
