import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DateTime } from 'luxon';
import { Chart, ChartDataset, Color, TooltipItem } from 'chart.js';
import 'chartjs-adapter-luxon';
import { BandwidthDatapoint } from '../../models/bandwidth.model';
import { BpsPipe, BpsUnit } from '@shared/pipes/bps.pipe';
import { UtilitiesService } from '@shared/services/utilities.service';
import * as defaultTheme from '../../themes/default.theme';
import { Status } from '@shared/models/status.model';
import '../../chart-types/centre.positioner';
import { ChartSize } from '../../models/chart.model';
import { BarChartOptions } from '../../models/bar-chart.model';
import {
  LegendItem,
  LegendPosition,
  LegendSize,
  LegendType,
} from '../../models/legend.model';
import annotationPlugin, {
  AnnotationOptions,
  LineAnnotationOptions,
} from 'chartjs-plugin-annotation';

type BandwidthAnnotationOptions = {
  in95: AnnotationOptions & LineAnnotationOptions;
  out95: AnnotationOptions & LineAnnotationOptions;
};

Chart.register(annotationPlugin);

@Component({
  selector: 'app-bandwidth-chart',
  templateUrl: './bandwidth-chart.component.html',
  styleUrls: ['./bandwidth-chart.component.scss'],
})
export class BandwidthChartComponent implements OnInit, OnChanges {
  @Input() data: BandwidthDatapoint[] | null = [];
  @Input() status: Status = Status.LOADING;
  @Input() size: ChartSize = ChartSize.LARGE;
  @Input() legendSize: LegendSize = LegendSize.MEDIUM;
  @Input() legendPosition: LegendPosition | false = LegendPosition.TOP;
  @Input() legendType: LegendType = LegendType.LIST;
  @Input() legendHeadings?: string[];

  public Status = Status;
  public LegendSize = LegendSize;
  public LegendPosition = LegendPosition;
  public LegendType = LegendType;

  public bandwidthChartOptions: BarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        type: 'time',
        stacked: true,
        bounds: 'data',
        ticks: {
          ...defaultTheme.fontSettings,
          color: defaultTheme.options.tickColor,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        type: 'linear',
        title: {
          display: true,
          text: '-',
          font: { ...defaultTheme.fontSettings.font },
          color: defaultTheme.fontSettings.color,
        },
        ticks: {
          callback: (value: string | number) => Math.abs(+value),
          font: { ...defaultTheme.fontSettings.font },
          color: defaultTheme.options.tickColor,
          precision: 2,
        },
        border: {
          display: false,
        },
        grid: {
          drawTicks: false,
          color: defaultTheme.options.gridColor,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultTheme.tooltipSettings,
        position: 'centre',
        multiKeyBackground: 'transparent',
        enabled: true,
        mode: 'index',
        intersect: false,
        boxPadding: 4,
        callbacks: {
          labelColor: function (context: any) {
            return {
              borderColor: 'transparent',
              backgroundColor:
                defaultTheme.bandwidthChartColors[context.datasetIndex]
                  .backgroundColor,
              borderWidth: 0,
              borderDash: [1, 0],
              borderRadius: 2,
            };
          },
          label: (tooltipItem: TooltipItem<'bar'>) => {
            const label = tooltipItem.dataset.label || '';
            return `${Math.abs(<number>tooltipItem.raw) ?? 0} ${
              this.bandwidthBpsUnit
            } ${label}`;
          },
          title: (tooltipItem: TooltipItem<'bar'>[]) => {
            return DateTime.fromMillis(tooltipItem[0].parsed.x).toFormat(
              'HH:mm d MMM yyyy',
            );
          },
        },
      },
      annotation: {
        common: {
          drawTime: 'afterDatasetsDraw',
        },
        annotations: {},
      },
    },
  };
  public bandwidthChartColors: Color[] = [];
  public bandwidthChartLabels: any[] = []; // Label[] = [];
  public bandwidthChartData: ChartDataset[] = [
    {
      data: new Array(360).fill(0),
      label: 'Inbound',
      backgroundColor: defaultTheme.bandwidthChartColors[0].backgroundColor,
      hoverBackgroundColor:
        defaultTheme.bandwidthChartColors[0].hoverBackgroundColor,
    },
    {
      data: new Array(360).fill(0),
      label: 'Outbound',
      backgroundColor: defaultTheme.bandwidthChartColors[1].backgroundColor,
      hoverBackgroundColor:
        defaultTheme.bandwidthChartColors[1].hoverBackgroundColor,
    },
  ];
  public bandwidthBpsUnit: BpsUnit | undefined;
  public legendItems!: LegendItem[][];

  private theme: any = defaultTheme;

  constructor(
    private bpsPipe: BpsPipe,
    private utilitiesService: UtilitiesService,
  ) {}

  ngOnInit(): void {
    this.setupChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setup95Lines();
    if (changes?.data?.currentValue) {
      this.data = changes.data.currentValue;
      if (this.data) {
        this.formatBandwidthData(this.data);
      }
    }
  }

  private setup95Lines() {
    if (this.bandwidthChartOptions?.plugins?.annotation?.annotations) {
      const annotationOptions: BandwidthAnnotationOptions = {
        in95: {
          type: 'line',
          endValue: 0,
          value: 0,
          borderColor: this.theme.annotationColors.borderColorIn,
          borderWidth: 1.5,
          scaleID: 'y',
          label: {
            display: false,
            content: '95%ile inbound',
          },
          borderDash: [15, 5],
        },
        out95: {
          type: 'line',
          endValue: 0,
          value: 0,
          borderColor: this.theme.annotationColors.borderColorOut,
          borderWidth: 1.5,
          scaleID: 'y',
          label: {
            display: false,
            content: '95%ile outbound',
          },
          borderDash: [15, 5],
        },
      };
      this.bandwidthChartOptions.plugins.annotation.annotations =
        annotationOptions;
    }
  }

  setupChart(): void {
    this.bandwidthChartColors = this.theme.bandwidthChartColors;
  }

  /**
   * Format the bandwidth data - put into the right format (GB, MB, kB or B)
   * @param bandwidthResults The results from the analytics service
   */
  formatBandwidthData(bandwidthResults: BandwidthDatapoint[]): void {
    // Find biggest number in order to get unit to use
    const allArray: number[] = [];
    const inArray: number[] = [];
    const outArray: number[] = [];
    bandwidthResults.forEach((datapoint) => {
      allArray.push(datapoint.bytes_rx || 0);
      allArray.push((datapoint.bytes_tx || 0) * -1);
      inArray.push(datapoint.bytes_rx || 0);
      outArray.push((datapoint.bytes_tx || 0) * -1);
      outArray.push(0);
    });

    // Get max, min and absolute max values
    const maxValue = Math.max(...allArray);
    const minValue = Math.min(...allArray);
    const maxAbsValue = Math.max(maxValue, Math.abs(minValue));
    const unitToUse = this.utilitiesService.checkUnitToUse(maxAbsValue);
    switch (unitToUse) {
      case 'tera':
        this.bandwidthBpsUnit = 'Tbps';
        break;
      case 'giga':
        this.bandwidthBpsUnit = 'Gbps';
        break;
      case 'mega':
        this.bandwidthBpsUnit = 'Mbps';
        break;
      case 'kilo':
        this.bandwidthBpsUnit = 'kbps';
        break;
      case 'unit':
      default:
        this.bandwidthBpsUnit = 'bps';
        break;
    }

    // Update yAxes label
    if (this.bandwidthChartOptions.scales?.y?.title) {
      this.bandwidthChartOptions.scales.y.title.text = this.bandwidthBpsUnit;
    }

    // Need to add annotations before populating data
    this.updateAnnotations(inArray, outArray);

    const chartLabels: string[] = [];

    // Convert all values to relevant unit
    let index = 0;
    bandwidthResults.forEach((datapoint: BandwidthDatapoint) => {
      chartLabels[index] = DateTime.fromMillis(datapoint.datetime).toISO();
      if (this.bandwidthChartData?.[0]?.data) {
        this.bandwidthChartData[0].data[index] = +this.bpsPipe.transform(
          datapoint.bytes_rx || 0,
          false,
          2,
          'bps',
          this.bandwidthBpsUnit as BpsUnit,
        );
      }
      if (this.bandwidthChartData?.[1]?.data) {
        this.bandwidthChartData[1].data[index] = +this.bpsPipe.transform(
          (datapoint.bytes_tx || 0) * -1,
          false,
          2,
          'bps',
          this.bandwidthBpsUnit as BpsUnit,
        );
      }
      index++;
    });
    // When filtering, API does not always return the same amount of datapoints
    // in case it returns less - we need to cut the ones from before
    this.bandwidthChartData[0].data.length = index;
    this.bandwidthChartData[1].data.length = index;

    // Setting labels in one go means chart redraws only once
    this.bandwidthChartLabels = chartLabels;
    this.generateLegendItems();
  }

  generateLegendItems(): void {
    this.legendItems = [[]];
    this.bandwidthChartData.forEach((dataset) => {
      const maxValue = Math.max(...(dataset.data as number[]));
      const minValue = Math.min(...(dataset.data as number[]));
      const chosenValue = dataset.label === 'Inbound' ? maxValue : minValue;
      if (Math.abs(chosenValue) === Infinity) {
        return;
      }
      this.legendItems[0].push({
        label: `${dataset.label} (max)`,
        swatchColor: dataset.backgroundColor as Color,
        value: `${Math.abs(chosenValue)} ${this.bandwidthBpsUnit}`,
        sortvalue: Math.abs(chosenValue),
      });
    });
    const annotations =
      this.bandwidthChartOptions?.plugins?.annotation?.annotations ?? {};
    Object.values(annotations).forEach((annotation: any) => {
      this.legendItems[0].push({
        label: annotation?.label?.content || '',
        swatchColor: annotation.borderColor || '',
        value: `${Math.abs(+annotation?.value)} ${this.bandwidthBpsUnit}`,
        sortvalue: Math.abs(+annotation?.value),
      });
    });
  }

  /**
   * Add 95%ile annotations
   * *95 = 95% value in bps
   * *95Value = 95% value to plot on chart
   * *95String = 95% string to show on label (including units)
   */
  private updateAnnotations(inArray: number[], outArray: number[]) {
    const in95 = this.find95Percentile(inArray);
    const in95Value = +this.bpsPipe.transform(
      in95,
      false,
      2,
      'bps',
      this.bandwidthBpsUnit as BpsUnit,
    );
    const out95 = this.find95Percentile(outArray);
    const out95Value = +this.bpsPipe.transform(
      out95,
      false,
      2,
      'bps',
      this.bandwidthBpsUnit as BpsUnit,
    );

    const annotations = <BandwidthAnnotationOptions>(
      this.bandwidthChartOptions?.plugins?.annotation?.annotations
    );

    if (annotations?.in95) {
      annotations.in95.value = in95Value;
      annotations.in95.endValue = in95Value;
    }

    if (annotations?.out95) {
      annotations.out95.value = out95Value;
      annotations.out95.endValue = out95Value;
    }
  }

  /**
   * Calculate the 95th percentile from an array
   * "Linear interpolation between closest ranks" method
   * Adapted from : https://gist.github.com/IceCreamYou/6ffa1b18c4c8f6aeaad2
   *
   * @param targetArray The array of values to find the 95%ile from
   */
  private find95Percentile(targetArray: number[]) {
    if (targetArray.length === 0) {
      return 0;
    }

    targetArray.sort((a, b) => {
      return Math.abs(a) < Math.abs(b) ? -1 : 1;
    });

    const index = (targetArray.length - 1) * 0.95;
    const lower = Math.floor(index);
    const upper = lower + 1;
    const weight = index % 1;

    if (upper >= targetArray.length) {
      return targetArray[lower];
    }
    return targetArray[lower] * (1 - weight) + targetArray[upper] * weight;
  }
}
